.back-to-top-button {
position: fixed;
bottom: 20px;
right: 20px;
transition: opacity 0.3s;
opacity: 0;
cursor: pointer;
z-index: 1000;
}

.back-to-top-button.visible {
opacity: 1;
}

.up-button {
background-color: #a855f7;
color: white;
cursor: pointer;
font-size: 25px;
bottom: 50px;
height: 50px;
width: 50px;
border-radius: 50%;
display: flex;
align-items: center;
justify-content: center;
border: none;
outline: none;
transition: background-color 0.3s, transform 0.3s;
}

.up-button:hover {
background-color: #702dae;
}